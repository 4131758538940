<!-- Ported over and generalized from it's original use in AdvertisementMediaContent -->
<!-- PRECONDITION: The advertisement must have 'video' src prop and a unique identifier 'id' prop -->

<!-- To see the changes, see the PR: https://github.com/Adison-io/adison/pull/708 -->

<template>
  <div>
    <!-- Thumbnail -->
    <div v-show="showThumbnail" class="relative cursor-pointer" @mouseenter="mediaWasHovered = true">
      <img 
        ref="media" 
        :src="advertisement.thumbnail" 
        alt="thumbnail" 
        class="w-full object-contain"
        :class="{'rounded-lg': rounded}"
        @load="loadingThumbnail = false, $emit('loaded-thumbnail')"
      >
      <!-- Play button -->
      <transition>
        <div v-if="!loadingThumbnail && !carouselLoading" class="absolute inset-0 flex items-center justify-center">
          <div class="bg-black h-14 w-14 flex justify-center items-center rounded-full box-border absolute bg-opacity-30" key="play">
            <img src="../../assets/icons/play-icon.svg" alt="" class="w-5 h-5 pl-0.5">
          </div>
        </div>
      </transition>
    </div>
    <!-- Video Player -->
    <div v-show="!showThumbnail" class="relative flex overflow-hidden cursor-pointer" @click.stop.prevent="() => {}"
    @mouseenter="hoverScrub(), resize()" @mouseleave="showHoverScrub = false">
      <video 
        ref="videoPlayer"
        type="video/mp4"
        class="object-contain w-full h-full cursor-pointer"
        :class="{'rounded-lg': rounded}"
        :poster="advertisement.thumbnail"
        :controls="showControls && !showHoverScrub && !showPlayButton"
        preload="metadata"
        @timeupdate="debouncedUpdateVariable"
        @loadeddata="$emit('loaded-content')"
        @loadedmetadata="updateVariable"
        @click="togglePlayPause()"
      >
        <source :src="advertisement.video" >
      </video>
      <!-- Play Button -->
      <div v-if="showPlayButton" class="absolute inset-0 flex items-center justify-center select-none">
        <div class="bg-black h-14 w-14 flex justify-center items-center rounded-full box-border absolute bg-opacity-30">
          <img src="../../assets/icons/play-icon.svg" alt="" class="w-5 h-5 pl-0.5">
        </div>
      </div>
      <!-- Time Stamp -->
      <transition>
        <div v-if="showHoverScrub && currentTime" class="absolute flex w-full justify-end cursor-default pointer-events-none">
          <BaseText type="label" size="xs" class="text-white text-xs w-max py-1 px-1.5 m-3 z-10 rounded bg-black 
          bg-opacity-30 cursor-none hidden md:block pointer-events-none">
            {{ currentTime }}
          </BaseText>
        </div>
      </transition>
      <!-- Hover Scrub -->
      <transition>
        <div v-if="showHoverScrub" class="w-full h-full flex justify-center absolute bottom-0"
        @click.stop.prevent="togglePlayPause()">
          <div class="w-11/12 h-full flex">
            <!-- Line -->
            <div class="absolute w-11/12 bottom-0 h-full cursor-pointer bg-white bg-transparent bg-opacity-0">
              <div v-if="isScrubbing" class="absolute bottom-0 h-full w-0.5 object-contain cursor-pointer bg-white ml-3.5"
              :style="{left: `${linePosition * 87 + 1.5}%`}" />
            </div>
            <!-- Scroll Bar -->
            <div class="w-full h-10 rounded-md flex items-center bg-black bg-opacity-10 cursor-pointer bottom-0 mb-4 self-end overflow-hidden"
            style="background: rgba(0, 0, 0, 0.3); backdrop-filter: blur(2px); cursor: none;"
            @click.stop.prevent="clickScrub" @mouseenter="isScrubbing = true" @mouseleave="leaveScrub()">
              <div class="w-full absolute inset-0 flex items-center justify-center transition-opacity duration-200 bottom-0"
              :class="isScrubbing ? 'opacity-25' : 'opacity-100'" style="cursor: none">
                <BaseText type="heading" size="overline" class="z-10 text-white text-opacity-60" style="cursor: none">
                  HOVER SCRUB
                </BaseText>
              </div>
              <div class="absolute left-3.5 inset-0 cursor-pointer z-30 w-11/12" style="cursor: none"
              @mousemove="isScrubbing && updateTime($event)" @click="clickScrub"/>
              <img src="../../assets/icons/scrub.svg" style="cursor: none" alt="" class="h-2.5 z-20 opacity-0 absolute"
              :class="isScrubbing ? 'opacity-100' : 'opacity-0'" :style="{left: `${linePosition * 87 + 1.5}%`}">
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'BaseAdvertisementVideoPlayer',
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    },
    rounded: {
      type: Boolean,
      default: false
    },
    carouselLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // UI States
      currentTime: '',
      mediaWasHovered: false,
      showControls: false,
      showPlayButton: true,
      linePosition: 0,
      loadingThumbnail: true,

      // Hover scrub states
      showHoverScrub: false,
      isScrubbing: false,
      linePosition: false,
    }
  },
  mounted () {
  },
  beforeDestroy () {
    if (this.getAdPlaying === this.advertisement.id) {
      this.SET_AD_PLAYING(null)
      this.reset()
    }
  },
  watch: {
    advertisement (oldValue, newValue) {
      this.SET_AD_PLAYING(null)
      this.reset()
      if (newValue.thumbnail && oldValue.thumbnail !== newValue.thumbnail) {
        this.loadingThumbnail = true
      }
      if (newValue.video && oldValue.video !== newValue.video) {
        this.$refs.videoPlayer.load()
      }
    },
    getAdPlaying (newValue) {
      if (newValue !== this.advertisement.id) {
        this.reset()
      }
    },
    currentTime (newValue) {
      if (newValue === '0:00 / 0NaN:0NaN') {
        this.currentTime = '0:00 / 0:00'
      }
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdPlaying']),
    showThumbnail () {
      return !this.mediaWasHovered && this.advertisement.thumbnail && !this.advertisement.thumbnail.includes('undefined')
    }
  },
  methods: {
    ...mapMutations('AdvertisementsModule', ['SET_AD_PLAYING']),
    async togglePlayPause () {
      try {
        if (this.$refs.videoPlayer && this.$refs.videoPlayer !== undefined) {
          this.SET_AD_PLAYING(this.advertisement.id)
          const video = this.$refs.videoPlayer
          if (video?.paused) {
            setTimeout(() => {
              video.play()
              this.showControls = true
              this.showPlayButton = false
              this.showHoverScrub = false
            }, 100)
          } else {
            video.pause()
            this.showControls = false
            this.showPlayButton = true
            this.showHoverScrub = true
          }
        }
      } catch (err) {
        console.log(this.$refs.videoPlayer.paused)
        console.error(err)
      }
    },
    hoverScrub () {
      if (!this.showControls) {
        this.showHoverScrub = true
      }
    },
    async leaveScrub () {
      try {
        if (!this.$refs.videoPlayer) return
        await this.$refs?.videoPlayer?.pause()
        setTimeout(() => {
          this.$refs.videoPlayer.currentTime = 0
          this.linePosition = 0
          this.isScrubbing = false
        }, 100)
      } catch (err) {
        console.log(err)
      }
    },
    resize () {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    async clickScrub () {
      try {
        if (!this.$refs.videoPlayer) return
        this.SET_AD_PLAYING(this.advertisement.id)
        const video = this.$refs?.videoPlayer
        if (video.paused) {
          video.play()
        }
      } catch (err) {
        console.log(err)
      }
      this.showControls = true
      this.showPlayButton = false
      this.showHoverScrub = false
      this.isScrubbing = false
    },
    async reset () {
      try {
        if (this.$refs.videoPlayer) {
          await this.$refs.videoPlayer?.pause()
          this.$refs.videoPlayer.currentTime = 0
        }
        this.mediaWasHovered = false
        this.showControls = false
        this.showPlayButton = true
        this.linePosition = 0
        this.showHoverScrub = false
        this.isScrubbing = false
        this.linePosition = false
      } catch (err) {
        console.log(err)
      }
    },

    // ============================== TIME STAMP METHODS ==============================
    // (Ported directly from the original version with some modifications)

    debouncedUpdateVariable: _.debounce(function (event) {
      this.updateVariable(event)
    }, 1),
    updateVariable (event) {
      const video = event.target
      const currentTime = Math.floor(video.currentTime)
      const duration = Math.floor(video.duration)

      if (this.currentTime === '0:0-1 / 0:00') {
        this.currentTime = '0:00 / 0:00'
      } else if (duration < 60) {
        const formattedCurrentTime =
          currentTime >= 10 ? currentTime : '0' + currentTime
        const formattedDuration = duration >= 10 ? duration : '0' + duration
        this.currentTime = `0:${formattedCurrentTime} / 0:${formattedDuration}`
      } else {
        const minutes = Math.floor(duration / 60)
        const seconds = duration % 60
        const formattedDuration = `${minutes}:${seconds >= 10 ? seconds : '0' + seconds}`

        if (currentTime >= 60) {
          const currentMinutes = Math.floor(currentTime / 60)
          const currentSeconds = currentTime % 60
          const formattedCurrentTime = `${currentMinutes}:${currentSeconds >= 10 ? currentSeconds : '0' + currentSeconds}`
          this.currentTime = `${formattedCurrentTime} / ${formattedDuration}`
        } else {
          const formattedCurrentTime =
            currentTime >= 10 ? currentTime : '0' + currentTime
          this.currentTime = `0:${formattedCurrentTime} / ${formattedDuration}`
        }
      }
    },
    updateTime (event) {
      if (!this.$refs.videoPlayer) return
      const video = this.$refs.videoPlayer
      const rect = event.target.getBoundingClientRect()
      const x = event.clientX - rect.left
      const percent = x / rect.width
      this.linePosition = percent

      if (video.paused) {
        const frameRate = 60
        const intervalFrames = Math.floor(frameRate / 10)
        let currentFrame = 0
        let newTime = 0
        let loadedMetadata = false

        // If the metadata has already loaded, update the video's current time immediately
        if (video.duration && video.duration > 0) {
          loadedMetadata = true
          currentFrame = Math.floor(video.duration * percent * frameRate)
          const newFrame =
            Math.floor(currentFrame / intervalFrames) * intervalFrames
          newTime = newFrame / frameRate
          video.currentTime = newTime
          newTime = isNaN(newTime) ? 0 : newTime
          this.currentTime = this.formatTime(newTime)
        }
        // If the metadata hasn't loaded yet, update the video's current time as soon as it's available
        const checkMetadataInterval = setInterval(() => {
          if (loadedMetadata) {
            clearInterval(checkMetadataInterval)
            currentFrame = Math.floor(video.duration * percent * frameRate)
            const newFrame =
              Math.floor(currentFrame / intervalFrames) * intervalFrames
            newTime = newFrame / frameRate
            video.currentTime = newTime
            this.currentTime = this.formatTime(newTime)
          }
        }, 50)
      }
    },
    formatTime (time, toDuration = null) {
      if (!toDuration && !this.$refs?.videoPlayer) return
      const currentTime = Math.floor(time)
      const duration = toDuration ? Math.floor(toDuration) : Math.floor(this.$refs.videoPlayer.duration)

      if (duration < 60) {
        const formattedCurrentTime =
          currentTime >= 10 ? currentTime : '0' + currentTime
        const formattedDuration = duration >= 10 ? duration : '0' + duration
        return `0:${formattedCurrentTime} / 0:${formattedDuration}`
      } else {
        const minutes = Math.floor(duration / 60)
        const seconds = duration % 60
        const formattedDuration = `${minutes}:${seconds >= 10 ? seconds : '0' + seconds}`

        if (currentTime >= 60) {
          const currentMinutes = Math.floor(currentTime / 60)
          const currentSeconds = currentTime % 60
          const formattedCurrentTime = `${currentMinutes}:${currentSeconds >= 10 ? currentSeconds : '0' + currentSeconds}`
          return `${formattedCurrentTime} / ${formattedDuration}`
        } else {
          const formattedCurrentTime =
            currentTime >= 10 ? currentTime : '0' + currentTime
          return `0:${formattedCurrentTime} / ${formattedDuration}`
        }
      }
    },

    // ======================== DIRECTLY INVOKED METHODS =========================

    playVideoAtTimestamp (time) {
      // This func is called directly on the component instance from a parent
      this.mediaWasHovered = true // hides the thumbnail
      this.$nextTick(() => {
        const video = this.$refs.videoPlayer
        video.currentTime = time
        if (video.paused) this.togglePlayPause()
      })
    }
  }
}
</script>

<style scoped>
.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>